// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
// require("turbolinks").start()
require("@rails/activestorage").start()
// require("channels")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

//require.context('../pdfs', true)

import '../stylesheets/application'
import 'intl-tel-input/build/css/intlTelInput.css';
import './bootstrap_custom.js'
import '@nathanvda/cocoon'

import toastr from 'toastr'
window.toastr = toastr

toastr.options = {
  "closeButton": true,
  "debug": false,
  "newestOnTop": true,
  "progressBar": true,
  "positionClass": "toast-top-right",
  "preventDuplicates": false,
  "onclick": null,
  "showDuration": "200",
  "hideDuration": "600",
  "timeOut": "3000",
  "extendedTimeOut": "600",
  "showEasing": "swing",
  "hideEasing": "linear",
  "showMethod": "fadeIn",
  "hideMethod": "fadeOut"
}

document.addEventListener("DOMContentLoaded", function () {

  $('.second-button').on('click', function () {
    $('.animated-icon2').toggleClass('open');
  });
  
  var politician_stats = ["#availability","#loyalty","#winRate","#workEthic"]
  var chosen_stat = politician_stats[Math.floor(Math.random()*politician_stats.length)]; 
  $(chosen_stat).collapse('show')
});
